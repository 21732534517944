/**
 * Loan calculator
 */

(function($) {

  function getExpiryRecord(value) {
    var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

    var expiryDate = new Date().getTime() + expiryPeriod;
    return {
      value: value,
      expiryDate: expiryDate
    };
  }

  function getParam(p) {
    var match = new RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  // Format number to Euro currency
  function formatNumber(num) {
    return num.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  // Calculate monthly payment
  function calculateMonthlyPayment(loanPeriod, amount) {
    let rate = 0;
    if (loanPeriod === 6) {
      rate = 1.13;
    } else if (loanPeriod === 12) {
      rate = 1.24;
    } else if (loanPeriod === 18) {
      rate = 1.36;
    }
    let monthlyPayment = (amount * rate) / loanPeriod;
    monthlyPayment = formatNumber(monthlyPayment);
    $('.monthly_payment').html(monthlyPayment);
  }

  // Render loans form URL
  function renderUrl(loanPeriod, amount) {
    if ($('.link-button.loans-form').length > 0) {
      let currentPage = window.location.pathname;
      let urlParams = window.location.search;
      let gclidParam = getParam('gclid');
      let gclsrcParam = getParam('gclsrc');
      let isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
      let gclidRecord = null;
      if (gclidParam && isGclsrcValid) {
        gclidRecord = getExpiryRecord(gclidParam);
        localStorage.setItem('gclid', JSON.stringify(gclidRecord));
      }
      let gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
      if (!(gclid && new Date().getTime() < gclid.expiryDate)) {
        gclid = '';
        localStorage.removeItem('gclid');
      }
      currentPage = currentPage.replace('/' + currentLanguage, '').substr(1).slice(0, -1);
      let queryBuilder = 'language=' + currentLanguage + '&lainahakemus=1';
      if (currentPage !== '') {
        queryBuilder += '&page=' + currentPage;
      }
      if (!isNaN(amount) && !isNaN(loanPeriod)) {
        queryBuilder += '&amount=' + amount + '&period=' + loanPeriod;
      }
      if (urlParams === '' && sessionStorage.getItem('urlParams') != null) {
        urlParams = sessionStorage.getItem('urlParams');
      }
      if (urlParams !== '') {
        sessionStorage.setItem('urlParams', urlParams);
        urlParams = urlParams.replace('?', '');
        queryBuilder += '&' + urlParams;
      }
      if (queryBuilder.indexOf('gclid') === -1 && gclid) {
        queryBuilder += '&gclid=' + gclid.value;
      }
      $('.link-button.loans-form').each(function() {
        let linkButton = $(this).find('a');
        let url = linkButton.attr('href');
        if (url.indexOf('amount') === -1 && url.indexOf('period') === -1) {
          url = url + '?' + queryBuilder;
        } else {
          url = url.split('?')[0];
          url = url + '?' + queryBuilder;
        }

        // Do not apply the href if we ara using English
        if (currentLanguage !== 'en') {
          linkButton.attr('href', url);
        }
      });
    }
  }


  function renderValue() {
    let slider = $('input.slider');
    let output = $('.amount-value');
    let valueBg = $('.range-slider-value');
    let maxValue = slider.attr('max');
    let amount = parseInt(slider.val());
    output.data('value', amount);
    let formatAmount = formatNumber(amount);
    output.html(formatAmount);
    const percent = maxValue !== 0 ? (slider.val() * 100 / maxValue) : 0;
    let value = 'calc(' + percent + '% - 0.8rem)';
    if (slider.val() === 1000) {
      value = 0;
    } else if (percent <= 10) {
      value = percent + '%';
    }
    valueBg.css('width', value);
    let loanPeriod = parseInt($('.period-btn.active-state').data('value'));
    renderUrl(loanPeriod, amount);
    calculateMonthlyPayment(loanPeriod, amount);
  }


  setTimeout(function() {
    let slider = $('input.slider');
    let amount = parseInt($('.amount-value').data('value'));
    let loanPeriod = 6;
    // set the default value slider:
    renderValue();
    // Update the current slider value (each time you drag the slider handle)
    slider.on('change input mousemove', function() {
      renderValue();
    });
    let button = $('.period-btn');
    button.click(function() {
      $('.period-btn').removeClass('active-state');
      $(this).addClass('active-state');
      loanPeriod = parseInt($(this).data('value'));
      amount = parseInt($('.amount-value').data('value'));
      renderUrl(loanPeriod, amount);
      // Calculate monthly payment
      calculateMonthlyPayment(loanPeriod, amount);
    });
  }, 200);


})(jQuery);