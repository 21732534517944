/**
 * Wrap buttons in a columns element in to a container
 */
let btns_already_wrapped = false;
let wrap_buttons_to_container = function() {

  if (!btns_already_wrapped) {

    let columns_column = document.querySelectorAll('.blocks .wp-block-columns .wp-block-column');
    if (columns_column.length) {
      for (let i = 0; i < columns_column.length; i++) {
        let addedToDocument = false;
        let wrapper = document.createElement("div");
        wrapper.className = "column-button-container";

        let nodesToWrap = columns_column[i].getElementsByClassName("wp-block-acf-button");

        for (var index = 0; index < nodesToWrap.length; index++) {
          var node = nodesToWrap[index];
          if (!addedToDocument) {
            node.parentNode.insertBefore(wrapper, node);
            addedToDocument = true;
          }
          node.parentNode.removeChild(node);
          wrapper.appendChild(node);
        }
      }
    }
  }
};


/**
 * Set a class and height to the shorter column to align the button on the bottom
 */
const columns_to_equalize = document.querySelectorAll('.blocks .wp-block-columns.equalize');
// treshold to make all columns taller, needed to avoid the bottom button to be too close to the element above
const treshold = 30;
let equalize_column_heights = function() {

  let w = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (w < 1120) {
    return;
  }

  if (columns_to_equalize.length) {
    for (let i = 0; i < columns_to_equalize.length; i++) {

      //let innerColumns = columns_to_equalize[i].querySelectorAll('.wp-block-columns__content__inner .wp-block-column');
      //let innerColumns = columns_to_equalize[i].querySelectorAll('.wp-block-columns__content > .wp-block-columns__content__inner');
      let innerColumns = columns_to_equalize[i].querySelectorAll('.wp-block-columns__content__inner');

      let currentTallest = 0;

      if (innerColumns.length) {
        for (let j = 0; j < innerColumns.length; j++) {
          innerColumns[j].style.height = "auto";
          innerColumns[j].classList.remove("element-add-abs-pos-btn");

          let columnH = innerColumns[j].clientHeight;

          if (columnH > currentTallest) {
            currentTallest = columnH;
          }
        }

        //console.log("currentTallest: " + currentTallest);

        // Loop again and set the class to the elem that does not match the tallest
        let set_to_both = false;
        for (let k = 0; k < innerColumns.length; k++) {

          let column_h_new = innerColumns[k].clientHeight;

          //console.log("column_h_new: " + column_h_new);


          if (column_h_new !== currentTallest) {
            if ((column_h_new + treshold) < currentTallest) {
              //console.log(innerColumns[k]);
              innerColumns[k].classList.add("element-add-abs-pos-btn");
              innerColumns[k].style.height = currentTallest + "px";
            } else {

              set_to_both = true;
            }
          }

          // If the elements heights are inside the treshold, add height to both
          if (set_to_both) {
            innerColumns[k].classList.add("element-add-abs-pos-btn");
            innerColumns[k].style.height = (currentTallest) + "px";
          }

        }

      }
    }
  }
};


let didResize = false;
window.addEventListener("resize", () => {
  didResize = true;
  setInterval(function() {
    if (didResize) {
      equalize_column_heights();
      didResize = false;
    }
  }, 350);
});

document.addEventListener('DOMContentLoaded', function() {
  // no wrap on the YLL website
  //wrap_buttons_to_container();
  let timer = setInterval(function() {
    equalize_column_heights();
    clearInterval(timer);
  }, 250);
}, false);