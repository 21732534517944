/**
 * Juicer mods
 */


function updateJuicerFilters() {
  // remove Filters: text in juicer filter
  var juicerfilter = jQuery('.j-display-filters');
  juicerfilter.contents()[0].remove();

  // translate "all" filter btn
  jQuery('.j-display-filters li.all').text(theme_strings.juicer_all);

  // translate load more btn
  jQuery('.j-paginate.juicer-button').text(theme_strings.juicer_loadmore);

}