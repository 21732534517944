/**
 * ACCORDION menu
 */
var component_faq_accordion = function(args) {

  // setup args
  var accordionElem = args.accordionElem,
    site = args.site;

  // validate
  if (!accordionElem) {
    //console.log('Invalid or not at this page');
    return;
  }


  //Hide currentPanel and show new panel.
  function showPanel(panel) {
    //Hide current one. First time it will be null.
    var expandedPanel = accordionElem.querySelector(".active-item");
    if (expandedPanel) {
      expandedPanel.classList.remove("active-item");
    }
    //Show new one
    panel.classList.add("active-item");
  }

  //when panel is clicked, handlePanelClick is called.
  function handlePanelClick(event) {
    showPanel(event.currentTarget);
  }

  var allPanelElems = accordionElem.querySelectorAll(".module-acf-faq__accordion__item");
  for (var i = 0, len = allPanelElems.length; i < len; i++) {
    allPanelElems[i].addEventListener("click", handlePanelClick);
  }
  //By Default Show first panel
  //showPanel(allPanelElems[0]);

};

/**
 * FAQ
 */
component_faq_accordion({
  accordionElem: document.querySelector('.module-acf-faq__accordion'),
  site: document.querySelector('.js-page'),
});


/**
 * FAQ data filter
 */
let component_faq_filter = function(args) {

  function showFiltered(currentFilter) {
    let filters = document.getElementsByClassName('module-acf-faq__filter--filter-btn');
    for (let i = 0; i < filters.length; i++) {
      filters[i].classList.remove('active-filter');
    }

    currentFilter.classList.add('active-filter');

    let posts = document.getElementsByClassName('module-acf-faq__accordion__item');

    // Hide all posts by default
    for (let j = 0; j < posts.length; j++) {
      posts[j].style.display = 'none';
    }

    let curFilter = currentFilter.getAttribute('data-filtervar');

    // Get all matching elements
    let visiblePosts = document.getElementsByClassName(curFilter);
    for (let k = 0; k < visiblePosts.length; k++) {
      // Show every matching element
      visiblePosts[k].style.display = 'list-item';
    }
  }

  function handleFilterClick(event) {
    showFiltered(event.currentTarget);
  }

  // Make filters react
  let filters = document.getElementsByClassName('module-acf-faq__filter--filter-btn');
  for (let i = 0; i < filters.length; i++) {
    filters[i].addEventListener('click', handleFilterClick);
  }


};
component_faq_filter();