/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init responsive videos
 */
fitvids();

// set current lang for the laskuri components
var currentLanguage = document.getElementById('language').value;

// flying elements, set class to parent element to target it in css
// overflow won't work without targeting the parent
let flying_elems = document.querySelectorAll('.has-flying-elems');
if (flying_elems.length) {
  flying_elems[0].parentElement.classList.add("flying-elems-parent");
}