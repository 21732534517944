const body = document.body;
const stickyElem = document.querySelector('.sticky-scroll-elem');
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
const delta = 15;
const showOffset = 280; // how much needs to be scrolled down before sticks
let lastScroll = 0;
let didScroll = false;


//window.addEventListener("scroll", () => {
function webtory_doStickyScroll() {
  const currentScroll = window.pageYOffset;
  if (currentScroll === 0 || currentScroll < showOffset) {
    body.classList.remove(scrollUp);
    body.classList.remove(scrollDown);
    stickyElem.classList.remove('is-stuck');
    return;
  }

  // Make sure to scroll more than delta
  if (Math.abs(lastScroll - currentScroll) <= delta) {
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && (currentScroll > showOffset)) {
    // down
    body.classList.remove(scrollUp);
    body.classList.add(scrollDown);
    stickyElem.classList.remove('is-stuck');
  } else if (currentScroll < lastScroll && body.classList.contains(scrollDown) && (currentScroll > showOffset)) {
    // up
    body.classList.remove(scrollDown);
    body.classList.add(scrollUp);
    stickyElem.classList.add('is-stuck');
  }
  lastScroll = currentScroll;
}



window.addEventListener("scroll", () => {
  didScroll = true;
});

setInterval(function() {
  if (didScroll) {
    webtory_doStickyScroll();
    didScroll = false;
  }
}, 250);