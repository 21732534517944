/**
 * A toggler for the blog cat on on mobile sizes
 */
let togglerInitialized = false;
let blog_cats_nav_toggler = function() {

  if (togglerInitialized) {
    return;
  }
  togglerInitialized = true;

  let w = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (w > 920) {
    return;
  }

  let cat_nav_parent = document.querySelector('.categories-nav > ul, .subpages-nav > ul');

  function handleCatItemClick(event) {
    event.preventDefault();
    cat_nav_parent.classList.toggle('aside-nav-toggled');

    //return false;
  }

  if (null != typeof cat_nav_parent && cat_nav_parent) {

    //let active_item = document.querySelector('.categories-nav li.active-cat, .subpages-nav li.current_page_item');
    let nav_toggler = document.querySelector('.aside-nav-toggler');
    if (null != nav_toggler) {
      nav_toggler.addEventListener("click", handleCatItemClick);
    }

  }
};


let didResize = false;
window.addEventListener("resize", () => {
  didResize = true;
});
setInterval(function() {
  if (didResize) {
    blog_cats_nav_toggler();
    didResize = false;
  }
}, 350);
blog_cats_nav_toggler();